import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { INVALID_META_DATA } from 'src/app/app-errors';
import { CatalogItemContext } from 'src/app/catalogs/catalog-item-context';
import { CatalogItemMetaData } from 'src/app/catalogs/catalog-item-meta-data';
import { MediaPathResolver } from 'src/app/media/media-path-resolver';
import { ProductInfo } from './product-info';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    private productViewedSource: Subject<CatalogItemContext>;

    constructor(private http: HttpClient, private resolver: MediaPathResolver) {
        this.productViewedSource = new Subject<CatalogItemContext>();
    }

    public get productViewed(): Observable<CatalogItemContext> {
        return this.productViewedSource.asObservable();
    }

    public getInfoForItem(item: CatalogItemContext): Observable<ProductInfo> {
        const meta = item.meta as CatalogItemMetaData;
        if (!meta?.host) {
            throw new Error(INVALID_META_DATA);
        }
        const url = this.resolver.resolve({
            host: meta.host,
            path: meta.path,
            name: 'product.json',
            localize: false
        });

        const timestamp = Math.round(new Date().getTime() / 1000);
        return this.http
            .get(url, {
                responseType: 'text',
                params: {
                    t: timestamp
                }
            })
            .pipe(map(x => JSON.parse(x) as ProductInfo));
    }

    public notifyProductView(info: CatalogItemContext) {
        this.productViewedSource.next(info);
    }
}
